<template>
  <button
    :is="buttonIs"
    :to="to"
    v-on="$listeners"
    :class="[
      'v-button',
      `v-button_${buttonColor}`,
    ]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VButton',

  props: {
    to: {
      type: Object,
      default: () => null,
    },
    tag: {
      type: String,
      default: 'button',
      validator(val) {
        return [
          'button',
          'router-link',
          'a',
        ].indexOf(val) !== -1;
      },
    },
    buttonColor: {
      type: String,
      default: 'blue',
      validator(val) {
        return [
          'blue',
          'neitral',
        ].indexOf(val) !== -1;
      },
    },
  },
  computed: {
    buttonIs() {
      return this.to
        ? 'router-link'
        : this.tag;
    },
  },
}
</script>

<style lang="scss" scoped>
.v-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px 11px 30px;
  height: 40px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  border: none;
  border-radius: 4px;
  outline: none;
  text-decoration: none;

  &[disabled] {
    pointer-events: none;
    opacity: .7;
  }
}

.v-button_blue {
  color: #FFF;
  background-color: $blue;

  @include dark-theme {
    color: #353535;
    background-color: $darkThemeBlue;
  }
}

.v-button_neitral {
  color: $lightThemeText;
  background-color: $lightThemeBackground !important;
}
</style>
